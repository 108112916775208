.loading-overlay {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(100, 100, 100, 0.7);
  z-index: 100000;
}
