import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core'
import { LoaderService } from '@core/services/api/loader.service'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { NgIf } from '@angular/common'
import { ProgressSpinnerComponent } from '@app/components/uiux/progress-spinner/progress-spinner.component'

@Component({
  selector: 'app-loading',
  standalone: true,
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
	imports: [
		ProgressSpinnerModule,
		NgIf,
		ProgressSpinnerComponent
	],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {

  protected loading: boolean = false

  constructor(
    private loader: LoaderService,
    private cd: ChangeDetectorRef,
  ) {
    this.loader.loading$.subscribe(isLoading => {
      this.loading = isLoading
      this.cd.markForCheck()
    })
  }

}
