import { Component, OnInit, Renderer2 } from '@angular/core'
import { MessageService } from 'primeng/api'
import locale from 'assets/i18n/de'
import { NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { LocalSearchService } from '@core/services/search.service'
import { LocationHelper } from '@core/common/location-helper'
import { CacheStorageService } from '@core/services/cache-storage.service'
import { UpdateService } from '@core/services/update.service'
import { PrimeNG } from 'primeng/config'
import { LoadingComponent } from '@app/components/uiux/loading/loading.component'
import { ConfirmDialog } from 'primeng/confirmdialog'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    LoadingComponent,
    ConfirmDialog,
    RouterOutlet
  ],
  standalone: true
})
export class AppComponent implements OnInit {

  private lastRouteNamespace: string = ''

  constructor(
    private config: PrimeNG,
    private router: Router,
    private localSearchService: LocalSearchService,
    private locationHelper: LocationHelper,
    private messageService: MessageService,
    private cacheService: CacheStorageService,
    private renderer2: Renderer2,
    private updateService: UpdateService,
  ) {}

  ngOnInit(): void {
    this.config.setTranslation(locale)

    this.updateService.init()

    // should fix "Failed to fetch dynamically imported module"
    this.renderer2.listen('document', 'vite:preloadError', (event) => {
      event.preventDefault()
      this.cacheService.clearAll()
      window.location.replace(window.location.href)
    })

    // register on route event to update things regularly when the namespace gets changed
    this.router.events.subscribe((event): void => {
      if (event instanceof NavigationEnd) {

        //check if Message has been queued for Route change
        const location = this.locationHelper.getState()
        if (location.toast) {
          setTimeout(() => {
            this.messageService.add({
            severity: location.toast?.severity,
            summary: location.toast?.summary,
            detail: location.toast?.detail,
          })}, 1000)
        }

        // skip logic for login route
        if (event.url.includes('/auth/')) {
          this.lastRouteNamespace = 'auth'
          return
        }

        // check for namespace switch and update unread notifications
        const routeNamespace: string = event.url.split('/')[1] || ''

        if (routeNamespace !== this.lastRouteNamespace) {
          // reset search input
          this.localSearchService.search('')

          this.lastRouteNamespace = routeNamespace
        }
      }
    })
  }
}
